import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Input,  message } from "antd";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import img from "../Authentication/login.svg";
import Layout from "../User/Layout";
import { useAuth } from "../utils/useAuth";
import Loader from "../Loader/Loader";

const ResetPasswordForm = () => {
	const [form] = Form.useForm();
	const { uidb64, token } = useParams();
	const navigate = useNavigate();
	const { apiurl } = useAuth();
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (values) => {
		setLoading(true);
		if (values.password !== values.confirmPassword) {
			message.error("Passwords do not match");
			setLoading(false);
			return;
		}

		try {
			const password = values.password;

			const response = await fetch(
				`${apiurl}/resetpassword/${uidb64}/${token}/`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ password }),
				}
			);

			if (!response.ok) {
				const errorData = await response.json();
				message.error(errorData.message);
				setLoading(false);
			}
			message.success("Reset Successful.! Redirecting you to login");
			form.resetFields();
			setLoading(false);
			setTimeout(() => {
				navigate("/login");
			}, 1500);
		} catch (error) {
			setLoading(false);
			message.error("An error occurred while resetting the password");
		}
	};

	if (loading) {
		return <Loader />;
	}

	return (
		<Layout>
			<div className="login-loginpage">
				<div>
					<Form
						form={form}
						className="form"
						title="Reset Password"
						layout="vertical"
						initialValues={{
							remember: true,
						}}
						onFinish={handleSubmit}
						autoComplete="off">
						<h1>Reset Password</h1>
						<Form.Item
							label="Password"
							name="password"
							rules={[
								{
									required: true,
									message: "Please input your password!",
								},
							]}>
							<Input.Password />
						</Form.Item>

						<Form.Item
							label="Confirm Password"
							name="confirmPassword"
							rules={[
								{
									required: true,
									message: "Please input your password!",
								},
							]}>
							<Input.Password />
						</Form.Item>

						<Form.Item>
							<Button
								className="login-submit-btn"
								type="primary"
								htmlType="submit">
								Submit
							</Button>
						</Form.Item>
					</Form>
				</div>

				<img className="login-girl-img" src={img} alt="img"></img>
			</div>
		</Layout>
	);
};

export default ResetPasswordForm;
