import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { useAuth } from "../utils/useAuth";
import img from "../Authentication/login.svg";
import Layout from "../User/Layout";
import Loader from "../Loader/Loader";

const ForgotPassword = () => {
	const { apiurl } = useAuth();
	const [loading, setLoading] = useState(false);

	const handleForgotPassword = async (values) => {
		setLoading(true);

		try {
			const response = await fetch(`${apiurl}/forgotpassword/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(values),
			});

			const data = await response.json();
			if (response.ok) {
				setLoading(false);
				message.success(data.success);
				form.resetFields();
			} else {
				setLoading(false);
				message.success(data.error);
			}
		} catch (error) {
			setLoading(false);
			message.error("An error occurred. Please try again.");
		}
	};

	const [form] = Form.useForm();

	if (loading) {
		return <Loader />;
	}

	return (
		<Layout>
			<div className="login-loginpage">
				<div>
					<Form
						form={form}
						layout="vertical"
						title="Forgot Password"
						className="form"
						initialValues={{
							remember: true,
						}}
						onFinish={handleForgotPassword}
						autoComplete="off">
						<h1>Forgot Password</h1>
						<Form.Item
							label="Email ID"
							name="email"
							rules={[
								{
									required: true,
									message: "Please input your username!",
								},
								{
									type: "email",
									message: "Please input your valid Email ID",
								},
							]}>
							<Input className="inp" />
						</Form.Item>

						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								className="login-submit-btn">
								Submit
							</Button>
						</Form.Item>
					</Form>
				</div>

				<img className="login-girl-img" src={img} alt="img"></img>
			</div>
		</Layout>
	);
};

export default ForgotPassword;
