import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import {
	SolutionOutlined,
	MenuOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Drawer, Button } from "antd";
import logo from "./../../images/logo.svg";

const { Header } = Layout;

const Main = ({ children }) => {
	const location = useLocation();
	const [openKeys, setOpenKeys] = useState([]);
	const [drawerVisible, setDrawerVisible] = useState(false);

	const defaultSelectedKeys = () => {
		const pathname = location.pathname;

		const menuItems = [
			"/delivery/orders",
			"/delivery/returns",
		];

		const index = menuItems.findIndex((item) => pathname.includes(item));
		return index !== -1 ? [`${index + 1}`] : [""];
	};

	const showDrawer = () => {
		setDrawerVisible(true);
	};

	const onClose = () => {
		setDrawerVisible(false);
	};

	const menuItems = [
		{
			key: "1",
			icon: <SolutionOutlined />,
			label: <Link to="/delivery/orders">Orders</Link>,
		},
		{
			key: "2",
			icon: <SolutionOutlined />,
			label: <Link to="/delivery/returns">Returns</Link>,
		},
		{
			key: "3",
			icon: <SolutionOutlined />,
			label: <Link to="/login">Logout</Link>,
		},
	];

	return (
		<Layout>
			<Header className="user-layout-head">
				<div className="user-layout-header-logo">
					<a href="/delivery/orders">
						<img alt="logo" src={logo}></img>
					</a>
				</div>
				<Button
					className="user-layout-mobile-menu-button"
					type="text"
					icon={<MenuOutlined />}
					onClick={showDrawer}
				/>
				<Menu
					mode="horizontal"
					theme="light"
					defaultSelectedKeys={defaultSelectedKeys()}
					className="user-layout-menu"
					items={menuItems}
				/>
			</Header>
			<Drawer
				title="Menu"
				placement="left"
				onClose={onClose}
				open={drawerVisible}>
				<Menu
					mode="vertical"
					theme="light"
					defaultSelectedKeys={defaultSelectedKeys()}
					openKeys={openKeys}
					onOpenChange={(keys) => setOpenKeys(keys)}
					items={menuItems}
				/>
			</Drawer>
			<Layout>
				<div className="user-layout-content">{children}</div>
			</Layout>
		</Layout>
	);
};

export default Main;
