import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../utils/useAuth";
import { Breadcrumb, Button, message } from "antd";
import Main from "./Layout";
import { HeartFilled, HeartOutlined, BookOutlined } from "@ant-design/icons";

const BookDetail = () => {
	const { id } = useParams();
	const { apiurl, token } = useAuth();
	const [book, setBook] = useState(null);
	const [iswishlist, setIswishlist] = useState(false);
	const [isAddedToCart, setIsAddedToCart] = useState(false);
	const [comments, setComments] = useState([]);
	const navigate = useNavigate();

	const fetchComments = async () => {
		try {
			const response = await fetch(`${apiurl}/bookreviews/${id}/`);
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const data = await response.json();
			setComments(data.data);
		} catch (err) {
			message.error("Failed to fetch comments");
		}
	};

	const AddWishList = async () => {
		if (!token) {
			return message.error("Please login to wishlist books");
		}
		try {
			const response = await fetch(`${apiurl}/wishlist/`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ id: id }),
			});
			const data = await response.json();
			if (response.ok) {
				message.success(data.message);
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("Failed to add to wishlist.");
		}
		IsWishList();
	};

	const RemoveWishList = async () => {
		try {
			const response = await fetch(`${apiurl}/wishlist/`, {
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ id: id }),
			});
			const data = await response.json();
			if (response.ok) {
				message.success(data.message);
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("Failed to remove from wishlist.");
		}
		IsWishList();
	};

	const IsWishList = async () => {
		try {
			const response = await fetch(`${apiurl}/iswishlisted/${id}/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			});
			if (response.ok) {
				const data = await response.json();
				setIswishlist(data.status);
			}
		} catch (error) {
			message.error("Failed to fetch wishlist status.");
		}
	};

	const AddCart = async () => {
		if (!token) {
			return message.error("Please login to add book to cart");
		}
		try {
			const response = await fetch(`${apiurl}/cart/`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ book: id }),
			});
			const data = await response.json();
			if (response.ok) {
				message.success(data.message);
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("Failed to add to Cart.");
		}
		IsAddedToCart();
	};

	const RemoveCart = async () => {
		try {
			const response = await fetch(`${apiurl}/cart/`, {
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ id: id }),
			});
			const data = await response.json();
			if (response.ok) {
				message.success(data.message);
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("Failed to remove from cart.");
		}
		IsAddedToCart();
	};

	const IsAddedToCart = async () => {
		try {
			const response = await fetch(`${apiurl}/isaddedtocart/${id}/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			});
			if (response.ok) {
				const data = await response.json();
				setIsAddedToCart(data.status);
			}
		} catch (error) {
			message.error("Failed to fetch cart status.");
		}
	};

	useEffect(() => {
		fetchBookDetails();
		IsWishList();
		IsAddedToCart();
		fetchComments();
	}, []);

	const fetchBookDetails = async () => {
		try {
			const response = await fetch(`${apiurl}/getbook/${id}/`, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			if (response.ok) {
				const data = await response.json();
				setBook(data.book);
			}
		} catch (error) {
			message.error("Failed to fetch book details.");
		}
	};

	const renderStars = (rating) => {
		return (
			<div className="review-rating">
				{[...Array(5)].map((_, i) => (
					<span
						key={i}
						className={`star ${i < rating ? "full" : "empty"}`}></span>
				))}
			</div>
		);
	};

	return (
		<Main>
			<div className="bd-main">
				<Breadcrumb
					items={[
						{
							title: (
								<Link to={"/catalogue"}>
									<BookOutlined />
									<span>Catalogue</span>
								</Link>
							),
						},
						{
							title: (
								<strong>
									<span>{book?.title}</span>
								</strong>
							),
						},
					]}
				/>
				<div className="book-details-main">
					<div className="book-details-container">
						<div className="book-image-container">
							{!iswishlist ? (
								<Button className="wishlist-button" onClick={AddWishList}>
									<HeartOutlined />
								</Button>
							) : (
								<Button className="wishlist-button" onClick={RemoveWishList}>
									<HeartFilled />
								</Button>
							)}
							<img
								className="book-image"
								src={`${apiurl}${book?.image}`}
								alt={book?.title}
							/>
						</div>
						<div className="book-info-container">
							<h1 className="book-title">{book?.title}</h1>
							<h3 className="book-author">Author: {book?.author}</h3>
							<p className="book-status">
								Copies Available: {book?.num_of_copies}
							</p>
							<pre className="book-description">{book?.description}</pre>
							<div className="book-actions">
								{isAddedToCart ? (
									<>
										<Button className="cart-button" onClick={RemoveCart}>
											Remove From Cart
										</Button>
										<Button
											className="go-cart-button"
											onClick={() => {
												navigate("/cart");
											}}>
											Go to cart
										</Button>
									</>
								) : (
									<Button className="cart-button" onClick={AddCart}>
										Add to Cart
									</Button>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="reviews-user">
					<h2>Reviews</h2>
					{comments && comments.length > 0 ? (
						comments.map((comment) => (
							<div key={comment.id} className="review-item">
								<h4 className="review-username">{comment.userprofile}</h4>
								<div className="review-rating">
									{renderStars(comment.rating)}
								</div>
								<p className="review-comment">{comment.comment}</p>
							</div>
						))
					) : (
						<center><p>No reviews yet.</p></center>
					)}
				</div>
			</div>
		</Main>
	);
};

export default BookDetail;
