import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Checkbox, Radio, Select } from "antd";
import "./signup.css";
import img from "./../../images/logo.svg";
import { useAuth } from "../utils/useAuth";
import { useNavigate } from "react-router-dom";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import Loader from "../Loader/Loader";

const { Option } = Select;

const Signup = () => {
	const [form] = Form.useForm();
	const { apiurl } = useAuth();
	const navigate = useNavigate();
	const [subscriptionPlans, setSubscriptionPlans] = useState([]);
	const [current, setCurrent] = useState(0);
	const [selectedPlan, setSelectedPlan] = useState(null);
	const [selectedPlanDetails, setSelectedPlanDetails] = useState(null);
	const [step1Values, setStep1Values] = useState({});
	const [step2Values, setStep2Values] = useState({});
	const [loading, setLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [coupons, setCoupons] = useState([]);
	const [selectedCoupon, setSelectedCoupon] = useState(null);
	const [validCoupon, setValidCoupon] = useState(null);
	const [existingEmails, setExistingEmails] = useState([]);
	const [amount, setAmount] = useState(null);
	const [planTypeFilter, setPlanTypeFilter] = useState("all");

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const fetchCoupons = async () => {
		try {
			const response = await fetch(`${apiurl}/copouncodes/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
			const data = await response.json();
			if (response.ok) {
				setCoupons(data.data);
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("Error fetching coupon codes");
		}
	};

	const fetchPlans = async () => {
		try {
			const response = await fetch(`${apiurl}/subscriptionplans/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
			const data = await response.json();
			if (response.ok) {
				setSubscriptionPlans(data.data);
			} else {
				message.error("Failed to fetch subscription plans");
			}
		} catch (error) {
			message.error("Error fetching subscription plans");
		}
	};

	useEffect(() => {
		fetchPlans();
		fetchCoupons();
		fetchEmails();
	}, []);

	const loadScript = () => {
		return new Promise((resolve) => {
			const script = document.createElement("script");
			script.src = "https://checkout.razorpay.com/v1/checkout.js";
			script.onload = () => resolve(true);
			script.onerror = () => resolve(false);
			document.body.appendChild(script);
		});
	};

	const fetchEmails = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchemails/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
			const data = await response.json();
			if (response.ok) {
				setExistingEmails(data.data);
			} else {
				message.error("Failed to fetch emails");
			}
		} catch (error) {
			message.error("Error fetching emails");
		}
	};

	const validForm = (values) => {
		if (!selectedPlan) {
			message.error("Please select a subscription plan");
			return false;
		}
		if (values.password !== values.confirmpassword) {
			message.error("Password and Confirm Password do not match");
			return false;
		}
		values = { ...values, amount: amount };
		setStep2Values(values);
		showRazorpay(values);
	};

	const showRazorpay = async (values) => {
		setLoading(true);
		const scriptLoaded = await loadScript();
		if (!scriptLoaded) {
			message.error("Failed to load payment script");
			setLoading(false);
			return;
		}

		let bodyData = new FormData();
		bodyData.append("subscription_plan", selectedPlan);
		bodyData.append("amount", amount);
		bodyData.append("code", selectedCoupon);
		bodyData.append("new", true);

		const response = await fetch(`${apiurl}/pay/`, {
			method: "POST",
			headers: {
				Accept: "application/json",
			},
			body: bodyData,
		});

		const data = await response.json();
		if (response.ok) {
			var options = {
				key: process.env.REACT_APP_PUBLIC_KEY,
				amount: data.payment.amount,
				currency: "INR",
				name: "Story Land Library",
				description: "Membership",
				order_id: data.payment.id,
				handler: function (response) {
					onFinish(response);
				},
				prefill: {
					name: "User's name",
					email: "User's email",
					contact: "User's phone",
				},
				notes: {
					address: "Razorpay Corporate Office",
				},
				theme: {
					color: "#3399cc",
				},
			};

			var rzp1 = new window.Razorpay(options);
			rzp1.open();
			setLoading(false);
		} else {
			message.error("Failed to initiate payment");
			setLoading(false);
		}
		setLoading(false);
	};

	const handleCouponChange = (value) => {
		setSelectedCoupon(value);
	};

	const applyCoupon = async () => {
		if (!selectedCoupon) {
			message.error("Please select a coupon code");
			return;
		}

		try {
			const response = await fetch(`${apiurl}/copouncodes/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					code: selectedCoupon,
				}),
			});
			const data = await response.json();
			if (response.ok) {
				setValidCoupon(data.code);
				const price = Number(selectedPlanDetails?.price) || 0;
				const cautionDeposit =
					Number(selectedPlanDetails?.caution_deposit) || 0;
				const onetimereg = Number(selectedPlanDetails?.one_time_reg_fee) || 0;
				const discountPercentage = Number(data.code) || 0;

				const total =
					price + onetimereg +
					cautionDeposit -
					(price + cautionDeposit + onetimereg) * (discountPercentage / 100);

				setAmount(total);
				message.success("Coupon applied successfully");
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("Error applying coupon");
		}
	};

	const onFinish = async (response) => {
		const allValues = {
			...step1Values,
			...step2Values,
			subscription_plan: selectedPlan,
			code: selectedCoupon,
			amount: amount,
			response: JSON.stringify(response),
			new: true,
		};

		if (allValues.password !== allValues.confirmpassword) {
			message.error("Password and confirm password must be the same");
			return;
		}

		try {
			setLoading(true);
			const response = await fetch(`${apiurl}/signup/`, {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify(allValues),
			});

			if (response.ok) {
				const data = await response.json();
				message.success(data.message);
				setLoading(false);
				navigate("/login");
			} else {
				setLoading(false);
				const errorData = await response.json();
				message.error(errorData.message);
			}
		} catch (error) {
			setLoading(false);
			message.error(error.message);
		}
		setLoading(false);
	};

	const next = () => {
		form
			.validateFields()
			.then((values) => {
				if (current === 0) {
					if (existingEmails.includes(values.email)) {
						message.error(
							"This email is already registered. Please use a different email."
						);
						return;
					}
					if (values.password !== values.confirmpassword) {
						message.error("Password and Confirm Password do not match");
						return;
					}
					setStep1Values(values);
				}
				if (current === 1) {
					if (selectedPlan) {
						setStep2Values(values);
						setCurrent(current + 1);
					} else {
						message.error("Please select a subscription plan");
					}
				} else {
					setCurrent(current + 1);
				}
			})
			.catch((errorInfo) => {
				console.log("Validate Failed:", errorInfo);
			});
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const handlePlanChange = (plan) => {
		setSelectedPlan(plan.id);
		setSelectedPlanDetails(plan);
		setAmount(null);
		setValidCoupon(null);
	};

	const handlePlanTypeChange = (e) => {
		setPlanTypeFilter(e.target.value);
	};

	const filteredPlans = subscriptionPlans.filter(
		(plan) => planTypeFilter === "all" || plan.s_type === planTypeFilter
	);

	const steps = [
		{
			content: (
				<div className="d-grid-2">
					<Form.Item
						name="firstname"
						label="First Name"
						rules={[
							{ required: true, message: "Please enter your First Name" },
						]}>
						<Input placeholder="First Name" />
					</Form.Item>
					<Form.Item
						name="lastname"
						label="Last Name"
						rules={[
							{ required: true, message: "Please enter your Last Name" },
						]}>
						<Input placeholder="Last Name" />
					</Form.Item>
					<Form.Item
						name="mobile"
						label="Mobile Number"
						rules={[
							{ required: true, message: "Please enter your Mobile Number" },
							{
								pattern: /^[0-9]{10}$/,
								message: "Please enter a valid mobile number",
							},
						]}>
						<Input placeholder="Mobile Number" />
					</Form.Item>
					<Form.Item
						name="email"
						label="Email"
						rules={[
							{ required: true, message: "Please enter your Email" },
							{ type: "email", message: "Please enter a valid email address" },
						]}>
						<Input placeholder="Your email" />
					</Form.Item>
					<Form.Item name="childname" label="Child Name">
						<Input placeholder="Child Name" />
					</Form.Item>
					<Form.Item name="dateofbirth" label="Date of Birth">
						<Input type="date" placeholder="Child Date of Birth" />
					</Form.Item>
					<Form.Item
						name="password"
						label="Password"
						rules={[{ required: true, message: "Please input your password" }]}>
						<Input
							type={showPassword ? "text" : "password"}
							placeholder="Create a password"
							suffix={
								showPassword ? (
									<EyeInvisibleOutlined onClick={togglePasswordVisibility} />
								) : (
									<EyeOutlined onClick={togglePasswordVisibility} />
								)
							}
						/>
					</Form.Item>
					<Form.Item
						name="confirmpassword"
						label="Confirm Password"
						rules={[
							{ required: true, message: "Please confirm your password" },
						]}>
						<Input
							type={showPassword ? "text" : "password"}
							placeholder="Confirm Your Password"
							suffix={
								showPassword ? (
									<EyeInvisibleOutlined onClick={togglePasswordVisibility} />
								) : (
									<EyeOutlined onClick={togglePasswordVisibility} />
								)
							}
						/>
					</Form.Item>
					<Form.Item name="subscribed_to_newsletter" valuePropName="checked">
						<Checkbox>Subscribe to Newsletter</Checkbox>
					</Form.Item>
				</div>
			),
		},
		{
			content: (
				<div>
					<Form.Item name="subscription_plan" label="Subscription Plan">
						<div className="plan-type-filter">
							<Radio.Group
								onChange={handlePlanTypeChange}
								value={planTypeFilter}>
								<Radio value="all">All</Radio>
								<Radio value="toys">Toys</Radio>
								<Radio value="books">Books</Radio>
								<Radio value="books_plus_toys">Books + Toys</Radio>
							</Radio.Group>
						</div>

						<div className="plans-table">
							<table>
								<thead>
									<tr>
										<th>Plan Name</th>
										<th>Duration</th>
										<th>Items Borrowed at a Time</th>
										<th>Subscription Fees</th>
										<th>Refundable Deposit</th>
										<th>One time registration fee</th>
										<th>Select</th>
									</tr>
								</thead>
								<tbody>
									{filteredPlans.map((plan) => (
										<tr
											key={plan.id}
											className={
												selectedPlanDetails?.id === plan.id
													? "current-plan"
													: ""
											}>
											<td>{plan.name}</td>
											<td>
												{plan.duration}{" "}
												{plan.offer ? `+ ${plan.offer.no_of_months_free}` : ""}{" "}
												Months
											</td>
											<td>{plan.books_allowed}</td>
											<td>₹{plan.price}</td>
											<td>₹{plan.caution_deposit}</td>
											<td>₹{plan.one_time_reg_fee}</td>
											<td>
												<Radio
													value={plan.id}
													checked={selectedPlan === plan.id}
													onChange={() => handlePlanChange(plan)}
												/>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</Form.Item>
				</div>
			),
		},
		{
			content: (
				<>
					<Form.Item
						name="coupon"
						label="Coupon Code"
						className="coupon-form-item">
						<div className="coupon-row">
							<Select
								value={selectedCoupon}
								onChange={handleCouponChange}
								placeholder="Select a coupon code"
								className="coupon-select">
								{coupons.map((coupon) => (
									<Option key={coupon.code} value={coupon.code}>
										{coupon.code} - {coupon.description}
									</Option>
								))}
							</Select>
							<Button onClick={applyCoupon} className="apply-coupon-btn">
								Apply Coupon Code
							</Button>
						</div>
					</Form.Item>

					<div className="plan-details">
						<h3 className="plan-item">
							Selected Plan: {selectedPlanDetails?.name}
						</h3>
						<h3 className="plan-item">
							Duration: {selectedPlanDetails?.duration} Months
						</h3>
						<h3 className="plan-item">
							Books Allowed: {selectedPlanDetails?.books_allowed}
						</h3>
						<h3 className="plan-item">
							Caution Deposit: ₹{selectedPlanDetails?.caution_deposit}
						</h3>
						<h3 className="plan-item">One time registration fee: ₹{selectedPlanDetails?.one_time_reg_fee}</h3>
						<h3 className="plan-item">Price: ₹{selectedPlanDetails?.price}</h3>
						<h3 className="plan-item">
							Coupon Discount: ₹
							{selectedPlanDetails?.price * (validCoupon / 100)}
						</h3>
						<h3 className="plan-item">
							Net Amount Payable: ₹
							{amount ||
								Number(selectedPlanDetails?.price) +
									Number(selectedPlanDetails?.caution_deposit) +
									Number(selectedPlanDetails?.one_time_reg_fee)}
						</h3>
					</div>
				</>
			),
		},
	];

	if (loading) {
		return <Loader />;
	}

	return (
		<>
				<div className="signup-cont">
					<div className="signup">
						<Form
							layout="vertical"
							form={form}
							className="signup-form"
							onFinish={validForm}
							initialValues={{ subscribed_to_newsletter: true }}
							name="signup">
							{steps[current].content}
							<div className="steps-action">
								<div className="signup-submit">
									{current > 0 && (
										<Button
											className="previous"
											style={{ margin: "0 8px" }}
											onClick={() => prev()}>
											Previous
										</Button>
									)}
									{current === steps.length - 1 && (
										<Form.Item name="submit">
											<Button
												type="primary"
												className="submit"
												htmlType="submit">
												Submit
											</Button>
										</Form.Item>
									)}
								</div>
								{current < steps.length - 1 && (
									<Button
										type="primary"
										className="next"
										onClick={() => next()}>
										Next
									</Button>
								)}
							</div>
						</Form>
					</div>
					<img src={img} alt="Signup" className="signup-img" />
				</div>
			
		</>
	);
};

export default Signup;
