import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import "./login.css";
import { useAuth } from "../utils/useAuth";
import { useNavigate ,Link} from "react-router-dom";
import img from "./login.svg";
import Layout from "../User/Layout"

const Login = () => {
	const { apiurl } = useAuth();
	const navigate = useNavigate();

	const onFinish = async (values) => {
		const response = await fetch(`${apiurl}/login/`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(values),
		});
		if (response.ok) {
			const data = await response.json();
			sessionStorage.setItem("slltok", data.access_token);
			localStorage.setItem("slltok", data.access_token);
			message.success("Logged in Successfully");
			if(data.role === 'management'){
				navigate("/admin/home");
				window.location.reload();
			}
			else if(data.role ==='delivery_agent'){
				navigate("/delivery/orders");
				window.location.reload();
			}
			else if (data.role === "user") {
				navigate("/");
				window.location.reload();
			}
		} else {
			const data = await response.json();
			message.error(data.error);
		}
	};
	const [form] = Form.useForm();

	return (
		<Layout >
		<div className="login-loginpage">
			<div>
			<Form
				form={form}
				title="Login"


				layout="vertical"
				className="form"
				initialValues={{ reasons: [] }}
				onFinish={onFinish}>
					<h1>Login</h1>
				<Form.Item
					name="email"
					label="Email"
					rules={[
						{
							required: true,
							message: "Please input your username!",
						},
					]}>
					<Input />
				</Form.Item>
				<Form.Item
					name="password"
					label="Password"
					rules={[
						{
							required: true,
							message: "Please input your password",
						},
					]}>
					<Input.Password className="pwd" />
					
				</Form.Item>
				
				
				<div className="login-forgot-link">
				<Link to="/forgot">Forgot Password</Link>
				</div>
				<Form.Item>
					<Button className="login-submit-btn" type="primary" htmlType="submit">
						Log In     -&gt;
					</Button>
				</Form.Item>

				<div className="login-signup-btn"> 
				Doesn't have an account? <Link to ="/signup">Sign Up</Link>
			</div>
				
			</Form>
			</div>
			
		
			<img className="login-girl-img" src={img} alt="img"></img>
		</div>
		</Layout>
	);
};

export default Login;
